import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/ClassAssembly/2023/3A/1.jpg';
import p2 from 'assests/Photos/ClassAssembly/2023/3A/2.jpg';
import p3 from 'assests/Photos/ClassAssembly/2023/3A/3.jpg';
import p4 from 'assests/Photos/ClassAssembly/2023/3A/4.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';

const ClassAssembly3A2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
        src: p3,
        source: p3,
        rows: 1.2,
        cols: 2,
    },
    {
      src: p1,
      source: p1,
      rows: 1.3,
      cols: 2,
    },
    {
        src: p4,
        source: p4,
        rows: 1.3,
        cols: 2,
    },
    {
      src: p2,
      source:p2,
      rows: 1.2,
      cols: 2,
    },  
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Karnataka Rajyothsava  
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 3A   Date: 30 & 31 OCTOBER 2023

        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        “Karnataka - the land of great rulers, thinkers and traditions continues to inspire generations.”
        The students of Class 3A presented their assembly on the topic, Karnataka Rajyothsava on Monday, 30 October 2023 and Tuesday, 31 October 2023 to mark the celebration of Karnataka Rajyothsava Day that is being observed on 1 November every year. 
        <br></br>
        The topic was introduced with a thought for the day which was spoken in Kannada stating how warm and welcoming Karnataka is, fostering a sense of pride and unity among its people. 
        <br></br>
        Interesting facts were delivered by two students, taking turns to speak about Sir M Visveshwaraiah, a renowned person of Karnataka who has remarkably contributed to our state’s development and has been awarded the Bharat Ratna for the same. Through these facts, the audience was enlightened about the hardships that Sir Visveshwaraiah faced to accomplish his education in order to pursue his dream of becoming an engineer.
        <br></br>
        The conversation was then followed by a mesmerising dance performance on the song ‘Kannada mannanu maribeda’ showcasing their patriotism towards the state. The students were dressed in yellow and red costumes to set in the spirits of Karnataka Rajyothsava - the state’s formation day.  The audience was enthralled by the performance. 
        </Typography>
        </Box>
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
        “The beauty of Karnataka lies in the diversity and the unique cultural blend that it offers.”
        <br/>
        </Typography>
        <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly3A2023;